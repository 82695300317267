import React, { useState } from "react"
import Aos from "aos"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroContainer from "../containers/home/hero"
import PreFooterContainer from "../containers/home/prefooter"
import ContactFormContainer from "../containers/home/contactform"
import ReviewsContainer from "../containers/home/reviews"
import MapsContainer from "../containers/home/maps"
import CardsContainer from "../containers/home/cards"
import "../constants/global.css"
import { PreContactFormContainer } from "../containers/home/precontactform"
import PreCardsContainer from "../containers/home/precards"

import {
  fetchChirurghiAsync,
  fetchCitiesAsync,
  fetchTrattamentiAsync,
  selectCity,
  selectTrattamenti,
  selectTrattamentiLoading,
  selectChirurghiSearch,
  resetChirurghi,
} from "../redux/searchSlice"
import { useDispatch, useSelector } from "react-redux"
import LoadingView from "../components/LoadingView/LoadingView"
import { navigate } from "gatsby"
import {
  fetchHomeAsync,
  selectBannerHome,
  selectChirurghiHome,
  selectDomande,
  selectHomeLoading,
  selectReviewshome,
  selectThumbs,
  selectTrattamentiHome,
} from "../redux/homeSlice"
import navigateToChirurghi from "../functions/navigateToChirurghi"
import getClosestCity from "../functions/getClosestCity"
import formatTrattamento from "../functions/formatTrattamento"
import { selectPosition } from "../redux/menuSlice"

const IndexPage = () => {
  const [trattamento, setTrattamento] = useState(null)
  const [city, setCity] = useState(null)

  const dispatch = useDispatch()
  const chirurghi = useSelector(selectChirurghiHome)
  const trattamenti = useSelector(selectTrattamenti)
  const trattamentiPopolari = useSelector(selectTrattamentiHome)
  const cities = useSelector(selectCity)
  const loading = useSelector(selectTrattamentiLoading)
  const homeLoading = useSelector(selectHomeLoading)
  const chirurghiSearch = useSelector(selectChirurghiSearch)
  const banner = useSelector(selectBannerHome)
  const questions = useSelector(selectDomande)
  const reviews = useSelector(selectReviewshome)
  const thumbs = useSelector(selectThumbs)
  const position = useSelector(selectPosition)

  React.useEffect(() => {
    Aos.init()
    dispatch(fetchHomeAsync())
    dispatch(fetchTrattamentiAsync())
    dispatch(fetchCitiesAsync())
  }, [])

  const onSearch = () => {
    navigateToChirurghi(city ? city.comune.toLowerCase() : getClosestCity(position, cities), trattamento ? formatTrattamento(trattamento.trattamento) : null)
  }

  if (!banner || loading || homeLoading) {
    return <LoadingView />
  }
  return (
    <Layout>
      <SEO title="Home" />
      <HeroContainer
        onSearch={onSearch}
        city={city}
        setCity={setCity}
        setTrattamento={setTrattamento}
        trattamento={trattamento}
        cities={cities}
        trattamenti={trattamenti}
        banner={banner}
      />
      {chirurghiSearch ? (
        <CardsContainer
          text={"Torna alla home"}
          onClick={() => {
            dispatch(resetChirurghi())
            setTrattamento(null)
            setCity(null)
            navigate("#")
          }}
          notitle
          doctors={chirurghiSearch}
        />
      ) : (
        <>
          <PreCardsContainer thumbs={thumbs} />
          <CardsContainer
            doctors={chirurghi}
            onClick={() => navigate("/chirurghi-estetici")}
          />
          <MapsContainer />
          <PreContactFormContainer
            trattamenti={trattamenti}
            questions={questions}
            onSubmit={(question, trattamento) => {
              navigate("/faqs", {
                state: {
                  question,
                  trattamento,
                },
              })
            }}
          />
          <ContactFormContainer cities={cities} trattamenti={trattamenti} />
          <ReviewsContainer reviews={reviews} />
        </>
      )}
      <PreFooterContainer trattamenti={trattamentiPopolari} />
    </Layout>
  )
}

export default IndexPage
