import React, { useEffect } from "react"

import MapSection from "../../components/MapSection/MapSection"
import { StarsWithPeople } from "../../components/Stars/Stars"
import GlobalMap from "../../components/GlobalMap/GlobalMap"

function MyComponent() {
  return (
    <MapSection>
      <MapSection.SubTitle>CERCA SULLA MAPPA</MapSection.SubTitle>
      <MapSection.Title>Il chirurgo più vicino a te</MapSection.Title>
      <GlobalMap />
    </MapSection>
  )
}

export default React.memo(MyComponent)
