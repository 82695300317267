import React from "react"
import { Container, Title, Body, Button } from "./styles/Jumbo"

const Jumbotron = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>
}

Jumbotron.Title = function JumbotronTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>
}

Jumbotron.Body = function JumbotronBody({ children, ...restProps }) {
  return <Body {...restProps}>{children}</Body>
}

Jumbotron.Button = function JumbotronButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>
}

export default Jumbotron
