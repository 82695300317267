import React from "react"
import useWindowDimensions from "./useWindowDimensions"
import { breakpoints } from "../constants/breakpoints"

const useBanner = banner => {
  const { width } = useWindowDimensions()

  return width < breakpoints.tabletSm
    ? [
        banner.descrizione_mobile?.length
          ? banner.descrizione_mobile
          : banner.descrizione,
        banner.url_foto_mobile?.length
          ? banner.url_foto_mobile
          : banner.url_foto,
      ]
    : [banner.descrizione, banner.url_foto]
}

export default useBanner
