import React from "react"
import {
  Title,
  Container,
  SubTitle,
  Wrapper,
  Mobile,
  SlidesContainer,
} from "./styles/Rev"

const Reviews = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>
}

Reviews.Mobile = function ReviewsMobile({ children, ...restProps }) {
  return <Mobile {...restProps}>{children}</Mobile>
}

Reviews.Wrapper = function ReviewsWrapper({ children, ...restProps }) {
  return <Wrapper {...restProps}>{children}</Wrapper>
}

Reviews.Title = function ReviewsTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>
}

Reviews.SlidesContainer = function ReviewsSlidesContainer({
  children,
  ...restProps
}) {
  return <SlidesContainer {...restProps}>{children}</SlidesContainer>
}

Reviews.SubTitle = function ReviewsTitle({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>
}

export default Reviews
