import React from "react"
import { useSelector } from "react-redux"

import PreCard from "../../components/PreCard/PreCard"
import PreCards from "../../components/PreCards/PreCards"
import {
  selectChirurgia,
  selectMedicina,
  selectMenuLoading,
} from "../../redux/menuSlice"

const PreCardLocal = ({
  img = require("../../images/Viso.png"),
  title,
  items,
  to,
  ...restProps
}) => {
  return (
    <PreCard {...restProps} to={to}>
      <PreCard.Wrapper>
        <PreCard.Image img={img}>
          <PreCard.ImageOverlay>
            <PreCard.Title>{title}</PreCard.Title>
          </PreCard.ImageOverlay>
        </PreCard.Image>
        <PreCard.Inner>
          {items.map(item => (
            <PreCard.InnerItem>{item}</PreCard.InnerItem>
          ))}
          <PreCard.Footer>Vedi tutti</PreCard.Footer>
        </PreCard.Inner>
      </PreCard.Wrapper>
    </PreCard>
  )
}

export default function PreCardsContainer({ thumbs }) {
  const medicina = useSelector(selectMedicina)
  const chirurgia = useSelector(selectChirurgia)
  const loading = useSelector(selectMenuLoading)

  //const trattamenti = medicina.trattamenti[thumbs.id_sys]
  return (
    <PreCards>
      <PreCards.Title>
        Cosa vorresti migliorare con la Chirurgia estetica?
      </PreCards.Title>
      <PreCards.Wrapper>
        {thumbs.map((t, i) => (
          <PreCardLocal
            img={t.foto_url}
            data-aos="fade-zoom-in"
            data-aos-delay={(i % 3) * 100}
            to={`/${t.categoria.toLowerCase()}`}
            items={
              !medicina.trattamenti
                ? []
                : { ...medicina.trattamenti, ...chirurgia.trattamenti }[
                    t.id_sys
                  ]
                    .slice(0, 3)
                    .map(t => t.trattamento)
            }
            title={t.descrizione_breve}
          />
        ))}
      </PreCards.Wrapper>
    </PreCards>
  )
}
