import styled from "styled-components"
import { device } from "../../../constants/breakpoints"
import { SectionTitle } from "../../../constants/global-styles"

export const Container = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  padding-bottom: 20px;
  margin-left: -15px;
  @media ${device.tablet} {
    margin-left: 0px;
  }
`

export const Wrapper = styled.div`
  max-width: 1550px;
  display: flex;
  position: relative;
  justify-content: center;
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
  @media ${device.laptop} {
    margin-left: 15px;
  }
`

export const Title = styled(SectionTitle)`
  padding-top: 60px;

  @media ${device.tablet} {
    font-size: 22px;
    width: 70%;
    padding-bottom: 10px;
    margin: auto;
  }
`
