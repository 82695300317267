import { Link } from "gatsby"
import styled from "styled-components"
import { device } from "../../../constants/breakpoints"

export const Wrapper = styled.div`
  max-width: 1100px;
  flex: 1;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 130px;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f8f9fa;
  padding-bottom: 40px;
`
export const TitleWrapper = styled.div`
  padding-top: 35px;
  padding-bottom: 10px;
`
export const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
`
export const Row = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;

  @media ${device.tablet} {
    flex-direction: column;
  }
`
export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media ${device.tablet} {
    flex-direction: row;
  }
`
export const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  @media ${device.laptop} {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
`
export const GridColumn = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

export const GridTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 30px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`
export const GridItem = styled.p`
  text-decoration: none;
  color: #212529;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    color: ${props => props.theme.blue};
    transition: 200ms ease-in;
  }

  @media ${device.tablet} {
    font-size: 13px;
  }
`
