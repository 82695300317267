import { Link } from "gatsby"
import styled from "styled-components"
import { device } from "../../../constants/breakpoints"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  max-width: 380px;
  background: white;
  padding: 10px;
  align-self: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  @media ${device.tablet} {
    margin: auto;
    margin-top: 50px;
  }
`
export const Title = styled.p`
  font-size: 25px;
  margin-top: 5px;
  font-weight: 700;
`
export const Body = styled.p`
  margin-left: 10%;
  text-align: center;
  margin-right: 10%;
`
export const Button = styled(Link)`
  background: #2e5589;
  border-radius: 3px;
  padding: 5px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;

  text-decoration: none;
  color: white;
  min-width: 180px;
  text-align: center;
  font-size: 12px !important;

  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 900;

  &:hover {
    color: white;
    background: #337ab7;
    transition: 200ms ease-in;
  }
`
