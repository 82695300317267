import React, { useEffect } from "react"
import Reviews from "../../components/Reviews/Reviews"
import Slider from "react-slick"

import ReviewCard from "../../components/ReviewCard/ReviewCard"
import Stars from "../../components/Stars/Stars"
import moment from "moment"
import "moment/locale/it"
import SeAll from "../../components/Atoms/SeAll"
import { navigate } from "gatsby-link"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,

  autoplay: false,

  responsive: [
    {
      breakpoint: 767,
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,

        autoplay: false,
      },
    },
  ],
}

const mocked = [
  {
    key: "r3q1",
    chirurgo: "Dott. Lucia Maria",
    trattamento: "Mastoplastica additiva",
    data_recensione: "17 novembre 2020",
    cliente: "Mario Rossi",
    rating: 3,
    messaggio:
      " Lorem ipsum dolor sit amet, consectetur odisciping elit. Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet,",
  },
  {
    key: "r3q1",
    chirurgo: "Dott. Lucia Maria",
    trattamento: "Mastoplastica additiva",
    data_recensione: "17 novembre 2020",
    cliente: "Mario Rossi",
    rating: 3,
    messaggio:
      " Lorem ipsum dolor sit amet, consectetur odisciping elit. Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet,",
  },
  {
    key: "r3q1",
    chirurgo: "Dott. Lucia Maria",
    trattamento: "Mastoplastica additiva",
    data_recensione: "17 novembre 2020",
    cliente: "Mario Rossi",
    rating: 3,
    messaggio:
      " Lorem ipsum dolor sit amet, consectetur odisciping elit. Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet,",
  },
]

export default function ReviewsContainer({ reviews = mocked, ...restProps }) {
  return (
    <Reviews {...restProps}>
      <Reviews.Wrapper>
        <Reviews.Title>Le ultime recensioni dei pazienti</Reviews.Title>
        <Reviews.SubTitle>
          Leggi le ultime recensioni sui nostri specialisti di <br />
          Chirurgia e Medicina estetica
        </Reviews.SubTitle>
        <Reviews.SlidesContainer>
          <Slider {...settings}>
            {reviews.map((r,i) => (
              <ReviewCard key={i+ "review"}>
                <ReviewCard.Row>
                  <ReviewCard.Title>{r.chirurgo}</ReviewCard.Title>
                  <ReviewCard.Date>
                    {moment(r.data_recensione).format("DD MMMM YYYY")}
                  </ReviewCard.Date>
                </ReviewCard.Row>
                <ReviewCard.Row>
                  <ReviewCard.SubTitle>{r.trattamento}</ReviewCard.SubTitle>
                  <Stars
                    style={{ marginBottom: 5 }}
                    fontSize={11}
                    count={r.rating}
                  />
                </ReviewCard.Row>
                <ReviewCard.Body>{r.messaggio}</ReviewCard.Body>
                <ReviewCard.Author>{r.cliente}</ReviewCard.Author>
              </ReviewCard>
            ))}
          </Slider>
        </Reviews.SlidesContainer>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <SeAll text={"Vedi tutte"} onClick={() => navigate("/esperienze")} />
        </div>
      </Reviews.Wrapper>
    </Reviews>
  )
}
