import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import CheckBox from "../../components/CheckBox/CheckBox"
import ContactForm from "../../components/Form/Form"
import { MenuItems } from "../../constants/MenuItems"
import {
  selectFormLoading,
  sendContactAsync,
} from "../../redux/contactFormSlice"
import { openErrorToast } from "../../redux/toastSlice"

export default function ContactFormContainer({ trattamenti, cities }) {
  const loading = useSelector(selectFormLoading)

  const [name, setName] = useState("")
  const [nameError, setNameError] = useState(false)
  const [surname, setSurname] = useState("")
  const [surnameError, setSurnameError] = useState(false)
  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState(false)
  const [city, setCity] = useState("")
  const [cityError, setCityError] = useState(false)
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [treatment, setTreatment] = useState("")
  const [treatmentError, setTreatmentError] = useState(false)
  const [accept, setAccept] = useState(false)
  const [message, setMessage] = useState("")
  const [acceptError, setAcceptError] = useState(false)
  const dispatch = useDispatch()

  const onSubmit = e => {
    e.preventDefault()
    if (name.length < 3) {
      setNameError(true)
    } else {
      setNameError(false)
    }
    if (surname.length < 3) {
      setSurnameError(true)
    } else {
      setSurnameError(false)
    }
    if (!phone.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)) {
      setPhoneError(true)
    } else {
      setPhoneError(false)
    }
    if (city.length == 0) {
      setCityError(true)
    } else {
      setCityError(false)
    }
    if (
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      setEmailError(true)
    } else {
      setEmailError(false)
    }
    if (treatment.length == 0) {
      setTreatmentError(true)
    } else {
      setTreatmentError(false)
    }
    if (!accept) {
      dispatch(openErrorToast("Devi prima accettare i termini"))
      return
    }
    if (
      name.length < 3 ||
      surname.length < 3 ||
      city.length == 0 ||
      !phone.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/) ||
      treatment.length == 0 ||
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      ) ||
      message.length == 0
    ) {
      dispatch(openErrorToast("Controlla i campi"))
      return
    }
    dispatch(
      sendContactAsync({
        nome: name,
        cognome: surname,
        telefono: phone,
        comune: city.comune,
        email,
        id_trattamento: treatment.id_sys,
        messaggio: message,
      })
    )
  }
  return (
    <ContactForm onSubmit={onSubmit}>
      <ContactForm.Wrapper data-aos="fade-up">
        <ContactForm.Title>
          Chiedi un preventivo ai nostri Chirurghi estetici
        </ContactForm.Title>
        <ContactForm.Row>
          <ContactForm.Input
            type="text"
            name="name"
            value={name}
            error={nameError}
            onChange={v => setName(v.target.value)}
            placeholder="Nome"
          />
          <ContactForm.Input
            type="text"
            name="cognome"
            value={surname}
            error={surnameError}
            onChange={v => setSurname(v.target.value)}
            placeholder="Cognome"
          />
        </ContactForm.Row>
        <ContactForm.Row>
          <ContactForm.Input
            type="text"
            value={phone}
            error={phoneError}
            onChange={v => setPhone(v.target.value)}
            placeholder="Telefono"
          />
          <ContactForm.AutoComplete
            freeSolo
            options={cities}
            getOptionLabel={option => option.comune}
            value={city}
            onChange={(event, newInputValue) => {
              setCity(newInputValue)
            }}
            renderInput={params => (
              <ContactForm.InputAuto
                error={cityError}
                {...params}
                label="Città"
              />
            )}
          />
        </ContactForm.Row>
        <ContactForm.Row>
          <ContactForm.Input
            type="email"
            error={emailError}
            value={email}
            onChange={v => setEmail(v.target.value)}
            placeholder="Email"
          />
          <ContactForm.AutoComplete
            freeSolo
            value={treatment}
            onChange={(event, newValue) => {
              setTreatment(newValue)
            }}
            options={trattamenti}
            getOptionLabel={option => option.trattamento}
            renderInput={params => (
              <ContactForm.InputAuto
                error={treatmentError}
                {...params}
                label="Trattamento"
              />
            )}
          />
        </ContactForm.Row>
        <ContactForm.InputLarge
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
        <CheckBox onClick={() => setAccept(!accept)}>
          continuando accetti i termini e condizioni sulla privacy
          <CheckBox.Input
            type="checkbox"
            checked={accept ? "checked" : false}
          />
          <CheckBox.CheckMark></CheckBox.CheckMark>
        </CheckBox>
        <ContactForm.Button loading={loading} type="submit">
          Invia
        </ContactForm.Button>
      </ContactForm.Wrapper>
    </ContactForm>
  )
}
