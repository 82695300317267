import styled from "styled-components"
import { device } from "../../../constants/breakpoints"

export const Container = styled.div``

export const Mobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`

export const SlidesContainer = styled.div`
  max-width: 1300px;
  margin: auto;
  padding: 50px;

  @media ${device.tablet} {
    padding: 0;
  }
`
export const Wrapper = styled.div`
  text-align: center;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  flex: 1;
`
export const Title = styled.p`
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #264a79;
  @media ${device.tablet} {
    font-size: 20px;
  }
`
export const SubTitle = styled.p`
  font-size: 18px;
  color: #5492e3;
  font-weight: 600;
  margin: auto;
  @media ${device.tablet} {
    font-size: 12px;
  }
`
