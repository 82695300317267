import React from "react"
import {
  Row,
  Container,
  Date,
  Body,
  Author,
  Title,
  SubTitle,
} from "./styles/RevCard"

const ReviewCard = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>
}

ReviewCard.Row = function ReviewCardRow({ children, ...restProps }) {
  return <Row {...restProps}>{children}</Row>
}

ReviewCard.Title = function ReviewCardTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>
}

ReviewCard.SubTitle = function ReviewCardSubTitle({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>
}

ReviewCard.Date = function ReviewCardDate({ children, ...restProps }) {
  return <Date {...restProps}>{children}</Date>
}

ReviewCard.Body = function ReviewCardBody({ children, ...restProps }) {
  return <Body {...restProps}>{children}</Body>
}

ReviewCard.Author = function ReviewCardAuthor({ children, ...restProps }) {
  return <Author {...restProps}>{children}</Author>
}

export default ReviewCard
