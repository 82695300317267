import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import {
  Inner,
  Wrapper,
  Container,
  Title,
  Footer,
  Image,
  InnerItem,
  ImageOverlay,
} from "./styles/PreCard.styled.js"

const PreCard = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>
}

PreCard.Wrapper = function CardWrapper({ children, ...restProps }) {
  return <Wrapper {...restProps}>{children}</Wrapper>
}

PreCard.Footer = function CardsFooter({ children, ...restProps }) {
  return (
    <Footer {...restProps}>
      {children}
      <FontAwesomeIcon
        style={{ fontSize: 14, marginLeft: 5, fontWeight: 100 }}
        icon={faArrowRight}
      />
    </Footer>
  )
}

PreCard.Inner = function CardInner({ children, ...restProps }) {
  return <Inner {...restProps}>{children}</Inner>
}
PreCard.InnerItem = function CardInnerItem({ children, ...restProps }) {
  return <InnerItem {...restProps}>{children}</InnerItem>
}

PreCard.Image = function CardImage({ children, ...restProps }) {
  return <Image {...restProps}>{children}</Image>
}

PreCard.ImageOverlay = function CardImageOverlay({ children, ...restProps }) {
  return <ImageOverlay {...restProps}>{children}</ImageOverlay>
}

PreCard.Title = function CardsSectionTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>
}

export default PreCard
