import {
  Container,
  Title,
  TitleWrapper,
  Row,
  Column,
  Grid,
  GridTitle,
  GridColumn,
  GridItem,
  GridWrapper,
  Wrapper,
} from "./styles/PreFoo"
import React from "react"

const PreFooter = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>
}

PreFooter.Wrapper = function PreFooterWrapper({ children, ...restProps }) {
  return <Wrapper {...restProps}>{children}</Wrapper>
}

PreFooter.TitleWrapper = function PreFooterTitleWrapper({
  children,
  ...restProps
}) {
  return <TitleWrapper {...restProps}>{children}</TitleWrapper>
}

PreFooter.Title = function PreFooterTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>
}

PreFooter.Row = function PreFooterRow({ children, ...restProps }) {
  return <Row {...restProps}>{children}</Row>
}

PreFooter.Column = function PreFooterColumn({ children, ...restProps }) {
  return <Column {...restProps}>{children}</Column>
}

PreFooter.GridWrapper = function PreFooterGridWrapper({
  children,
  ...restProps
}) {
  return <GridWrapper {...restProps}>{children}</GridWrapper>
}

PreFooter.Grid = function PreFooterGrid({ children, ...restProps }) {
  return <Grid {...restProps}>{children}</Grid>
}

PreFooter.GridColumn = function PreFooterGridColumn({
  children,
  ...restProps
}) {
  return <GridColumn {...restProps}>{children}</GridColumn>
}

PreFooter.GridTitle = function PreFooterGridTitle({ children, ...restProps }) {
  return <GridTitle {...restProps}>{children}</GridTitle>
}

PreFooter.GridItem = function PreFooterGridItem({ children, ...restProps }) {
  return <GridItem {...restProps}>{children}</GridItem>
}

export default PreFooter
