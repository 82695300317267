import React from "react"
import Jumbotron from "../../components/Jumbotron/Jumbotron"
import PreFooter from "../../components/PreFooter/PreFooter"
import { CHIRURGIA_FLAT, MEDICINA_FLAT } from "../../constants/trattamenti"
import navigateToTrattamento from "../../functions/navigateToTrattamento"

export default function PreFooterContainer({ trattamenti }) {
  return (
    <PreFooter>
      <PreFooter.Wrapper>
        <PreFooter.TitleWrapper>
          <PreFooter.Title>I trattamenti più popolari</PreFooter.Title>
        </PreFooter.TitleWrapper>
        <PreFooter.Row>
          <PreFooter.Column>
            <PreFooter.Grid>
              <PreFooter.GridColumn>
                <PreFooter.GridTitle>Chirurgia Estetica</PreFooter.GridTitle>
                {trattamenti["ChirurgiaEstetica"].slice(0, 6).map(i => (
                  <PreFooter.GridItem
                    onClick={() => navigateToTrattamento(i)}
                    key={i.id_sys}
                  >
                    {i.trattamento}
                  </PreFooter.GridItem>
                ))}
              </PreFooter.GridColumn>
              <PreFooter.GridColumn>
                <PreFooter.GridTitle>Medicina Estetica</PreFooter.GridTitle>
                {trattamenti["MedicinaEstetica"].slice(0, 6).map(i => (
                  <PreFooter.GridItem
                    onClick={() => navigateToTrattamento(i)}
                    key={i.id_sys}
                  >
                    {i.trattamento}
                  </PreFooter.GridItem>
                ))}
              </PreFooter.GridColumn>
            </PreFooter.Grid>
          </PreFooter.Column>
          <PreFooter.Column>
            <Jumbotron>
              <Jumbotron.Title>Sei un dottore?</Jumbotron.Title>
              <Jumbotron.Body>
                Scopri come entrare a far parte della nostra community dei
                migliori Chirurghi Estetici in italia
              </Jumbotron.Body>
              <Jumbotron.Button to={"/cosa_offriamo"}>
                SCOPRI DI PIÙ
              </Jumbotron.Button>
            </Jumbotron>
          </PreFooter.Column>
        </PreFooter.Row>
      </PreFooter.Wrapper>
    </PreFooter>
  )
}
