import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Wrapper, Container, Title } from "./styles/PreCards.styled.js"

const PreCards = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>
}

PreCards.Wrapper = function Cardsrapper({ children, ...restProps }) {
  return <Wrapper {...restProps}>{children}</Wrapper>
}

PreCards.Title = function CardsSectionTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>
}

export default PreCards
