import { Autocomplete } from "@material-ui/lab"
import React, { useEffect } from "react"
import HeroSection from "../../components/HeroSection/HeroSection"
import { cities } from "../../constants/cities"
import { Markup } from "interweave"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { breakpoints } from "../../constants/breakpoints"
import useBanner from "../../hooks/useBanner"
export default function HeroContainer({
  banner,
  trattamenti,
  cities,
  trattamento,
  setTrattamento,
  city,
  onSearch,
  setCity,
}) {
  const [desc, foto] = useBanner(banner)
  return (
    <HeroSection img={foto}>
      <HeroSection.Feature >
        <Markup tagName={"div"} content={desc} />
      </HeroSection.Feature>
      <HeroSection.BottomOverlay title={"home"}>
        <HeroSection.BottomOverlayInner>
          <HeroSection.BottomOverlayTitle></HeroSection.BottomOverlayTitle>
          <HeroSection.SearchContainer title={"home"}>
            <Autocomplete
              freeSolo
              value={trattamento}
              onChange={(event, newValue) => {
                setTrattamento(newValue)
              }}
              options={trattamenti}
              getOptionLabel={option => option.trattamento}
              renderInput={params => (
                <HeroSection.SearchLeft
                  title={"home"}
                  {...params}
                  label="Trattamento"
                />
              )}
            />
            <Autocomplete
              freeSolo
              options={cities}
              value={city}
              onChange={(event, newValue) => {
                setCity(newValue)
              }}
              getOptionLabel={option => option.comune}
              renderInput={params => (
                <HeroSection.SearchLeft
                  title={"home"}
                  {...params}
                  label="Località"
                />
              )}
            />
            <HeroSection.SearchButton
              title={"home"}
              onClick={onSearch}
              img={require("../../images/Search.png")}
            ></HeroSection.SearchButton>
          </HeroSection.SearchContainer>
        </HeroSection.BottomOverlayInner>
      </HeroSection.BottomOverlay>
    </HeroSection>
  )
}
