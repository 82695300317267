import { Link } from "gatsby"
import styled from "styled-components"
import { device } from "../../../constants/breakpoints"

export const Wrapper = styled.div`
  margin: 30px;
  width: 330px;
  border-radius: 12px;
  box-shadow: 10px 10px 50px #0260d91a;
  @media ${device.tablet} {
    margin: 10px;
  }
`

export const Image = styled.div`
  background: url(${props => props.img}) center center/cover no-repeat;
  height: 200px;
  width: 100%;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  @media ${device.tablet} {
    height: 150px;
  }
`

export const ImageOverlay = styled.div`
  background: rgba(246, 246, 246, 0.71) 0% 0% no-repeat padding-box;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  height: 40px;
  text-align: center;
  align-items: center;
  @media ${device.tablet} {
    height: 30px;
  }
`

export const Inner = styled.div`
  padding: 5px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #eff5f9;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
`

export const InnerItem = styled.p`
  text-align: center;
  margin-bottom: 5px;

  transition: none;
`

export const Container = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &:hover {
    color: black;
  }
`

export const Title = styled.h1`
  padding-top: 10px;
  color: #172d4a;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: -10px !important;
  @media ${device.tablet} {
    font-size: 18px;
    padding-top: 5px;
  }
`

export const Footer = styled.div`
  width: 100%;
  text-align: center;
  color: #2e5589;
  font-size: 14px;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;
`
