import styled from "styled-components"
import { device } from "../../../constants/breakpoints"

export const Container = styled.div`
  background-color: white;
  max-width: 550px;

  margin-top: 40px;
  padding: 20px;
  box-shadow: 0 3px 6px #efefef;

  @media ${device.desktopL} {
    max-width: 450px;
  }
  @media ${device.desktop} {
    max-width: 450px;
  }
  @media ${device.tablet} {
    max-width: 350px;
  }
  @media ${device.tabletSm} {
    max-width: 400px;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
`
export const Title = styled.p`
  text-align: left;
  font-size: 22px;
  color: #264a79;
  font-weight: 600;
  @media ${device.tablet} {
    font-size: 14px;
  }
`
export const SubTitle = styled.p`
  font-size: 15px;
  color: ${props => props.theme.lightblue};
  font-weight: 700;
  @media ${device.tablet} {
    font-size: 12px;
  }
`
export const Body = styled.p`
  text-align: left;

  @media ${device.tablet} {
    font-size: 10px;
    line-height: 20px;
  }
`
export const Author = styled.p`
  text-align: right;
  font-weight: 700;
  color: #001229;
  @media ${device.tablet} {
    font-size: 13px;
  }
`
export const Date = styled.p`
  text-align: right;
  font-weight: 600;
  font-size: 14px;
  font-family: Poppins;
  color: ${props => props.theme.gray};
  @media ${device.tablet} {
    font-size: 10px;
  }
`
